import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { app_custom_inventory_transaction_options_formComponent } from './app.custom_inventory_transaction_options_form.component';
import { app_custom_lot_creation_formComponent } from './app.custom_lot_creation_form.component';
import { app_custom_material_creation_formComponent } from './app.custom_material_creation_form.component';
import { app_custom_outbound_orders_update_formComponent } from './app.custom_outbound_orders_update_form.component';
import { app_custom_location_editorComponent } from './app.custom_location_editor.component';
import { app_custom_lot_editorComponent } from './app.custom_lot_editor.component';
import { app_custom_project_editorComponent } from './app.custom_project_editor.component';
import { app_custom_sales_order_editorComponent } from './app.custom_sales_order_editor.component';
import { app_custom_contact_addresses_gridComponent } from './app.custom_contact_addresses_grid.component';
import { app_custom_excel_sales_order_tracking_import_gridComponent } from './app.custom_excel_sales_order_tracking_import_grid.component';
import { app_custom_inventory_transactions_gridComponent } from './app.custom_inventory_transactions_grid.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';
import { app_custom_outbound_material_scanned_gridComponent } from './app.custom_outbound_material_scanned_grid.component';
import { app_custom_outbound_orders_gridComponent } from './app.custom_outbound_orders_grid.component';
import { app_custom_purchase_order_lines_gridComponent } from './app.custom_purchase_order_lines_grid.component';
import { app_custom_sales_order_lines_gridComponent } from './app.custom_sales_order_lines_grid.component';
import { app_custom_inventory_transactions_hubComponent } from './app.custom_inventory_transactions_hub.component';
import { app_custom_outbound_orders_hubComponent } from './app.custom_outbound_orders_hub.component';
import { app_custom_inbound_procedure_codes_singleComponent } from './app.custom_inbound_procedure_codes_single.component';
import { app_custom_operationcodes_dd_singleComponent } from './app.custom_operationcodes_dd_single.component';
import { app_custom_outbound_procedure_codes_singleComponent } from './app.custom_outbound_procedure_codes_single.component';
import { app_custom_inbound_procedure_codes_multiComponent } from './app.custom_inbound_procedure_codes_multi.component';
import { app_custom_operationcodes_dd_multiComponent } from './app.custom_operationcodes_dd_multi.component';
import { app_custom_outbound_procedure_codes_multiComponent } from './app.custom_outbound_procedure_codes_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { EasyPost_ShellService } from './EasyPost.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { ShipTheory_ShellService } from './ShipTheory.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Manifesting_ShellService } from './Manifesting.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public Carriers: Carriers_ShellService,
    public Materials: Materials_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public EasyPost: EasyPost_ShellService,
    public Lots: Lots_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public Reports: Reports_ShellService,
    public ShipTheory: ShipTheory_ShellService,
    public Owners: Owners_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Locations: Locations_ShellService,
    public Inventory: Inventory_ShellService,
    public Notifications: Notifications_ShellService,
    public Invoices: Invoices_ShellService,
    public Manifesting: Manifesting_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opencustom_inventory_transaction_options_form(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Transaction Export Options',
        referenceName: 'custom_inventory_transaction_options_form',
        component: app_custom_inventory_transaction_options_formComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_inventory_transaction_options_formDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ transactions?: boolean, duty?: boolean, cancel?: boolean }> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_transaction_options_formComponent,
      'Inventory Transaction Export Options',
      mode,
      dialogSize
    )
  }
  public opencustom_lot_creation_form(inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_lot_creation_form');
    ShellService.openViewRequest$.next(
      {
        title: 'Create Lot',
        referenceName: 'custom_lot_creation_form',
        component: app_custom_lot_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_lot_creation_formDialog(
    inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ lotId?: number, confirm?: boolean }> {
    this.logger.log('app', 'custom_lot_creation_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_lot_creation_formComponent,
      'Create Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_material_creation_form(inParams:{ projectId?: number, ownerId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_material_creation_form');
    ShellService.openViewRequest$.next(
      {
        title: 'Create Material',
        referenceName: 'custom_material_creation_form',
        component: app_custom_material_creation_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_material_creation_formDialog(
    inParams:{ projectId?: number, ownerId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ materialId?: number, confirm?: boolean }> {
    this.logger.log('app', 'custom_material_creation_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_material_creation_formComponent,
      'Create Material',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_orders_update_form(inParams:{ orders: { orderId?: number, shipmentId?: number }[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_outbound_orders_update_form');
    ShellService.openViewRequest$.next(
      {
        title: 'Update Orders',
        referenceName: 'custom_outbound_orders_update_form',
        component: app_custom_outbound_orders_update_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_orders_update_formDialog(
    inParams:{ orders: { orderId?: number, shipmentId?: number }[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_outbound_orders_update_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_outbound_orders_update_formComponent,
      'Update Orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_location_editor(inParams:{ locationId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_location_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Location',
        referenceName: 'custom_location_editor',
        component: app_custom_location_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_location_editorDialog(
    inParams:{ locationId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_location_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_location_editorComponent,
      'Edit Location',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_lot_editor(inParams:{ lotId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_lot_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Lot',
        referenceName: 'custom_lot_editor',
        component: app_custom_lot_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_lot_editorDialog(
    inParams:{ lotId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_lot_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_lot_editorComponent,
      'Edit Lot',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_project_editor(inParams:{ projectId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_project_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Project',
        referenceName: 'custom_project_editor',
        component: app_custom_project_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_project_editorDialog(
    inParams:{ projectId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<{ confirm?: boolean }> {
    this.logger.log('app', 'custom_project_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_project_editorComponent,
      'Edit Project',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'custom_sales_order_editor',
        component: app_custom_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_contact_addresses_grid(inParams:{ entity: string, entityId: number, typeIds: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_contact_addresses_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Addresses',
        referenceName: 'custom_contact_addresses_grid',
        component: app_custom_contact_addresses_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_contact_addresses_gridDialog(
    inParams:{ entity: string, entityId: number, typeIds: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_contact_addresses_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_contact_addresses_gridComponent,
      'Addresses',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_excel_sales_order_tracking_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Tracking Import',
        referenceName: 'custom_excel_sales_order_tracking_import_grid',
        component: app_custom_excel_sales_order_tracking_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_excel_sales_order_tracking_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_excel_sales_order_tracking_import_gridComponent,
      'Tracking Import',
      mode,
      dialogSize
    )
  }
  public opencustom_inventory_transactions_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, materialIds?: number[], operationCodeIds?: number[] }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Transactions',
        referenceName: 'custom_inventory_transactions_grid',
        component: app_custom_inventory_transactions_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_inventory_transactions_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, materialIds?: number[], operationCodeIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_transactions_gridComponent,
      'Transactions',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_materials_udf_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Materials UDF',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_materials_udf_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Custom Materials UDF',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_material_scanned_grid(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Scanned barcodes',
        referenceName: 'custom_outbound_material_scanned_grid',
        component: app_custom_outbound_material_scanned_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_material_scanned_gridDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_outbound_material_scanned_gridComponent,
      'Scanned barcodes',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_outbound_orders_grid(inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_outbound_orders_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Outbound orders',
        referenceName: 'custom_outbound_orders_grid',
        component: app_custom_outbound_orders_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_outbound_orders_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_outbound_orders_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_outbound_orders_gridComponent,
      'Outbound orders',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_purchase_order_lines_grid',
        component: app_custom_purchase_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_lines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_lines_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Order lines',
        referenceName: 'custom_sales_order_lines_grid',
        component: app_custom_sales_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_lines_gridComponent,
      'Order lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_inventory_transactions_hub(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Inventory Transactions Hub',
        referenceName: 'custom_inventory_transactions_hub',
        component: app_custom_inventory_transactions_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_inventory_transactions_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_inventory_transactions_hubComponent,
      'Inventory Transactions Hub',
      mode,
      dialogSize
    )
  }
  public opencustom_outbound_orders_hub(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_outbound_orders_hub');
    ShellService.openViewRequest$.next(
      {
        title: 'Outbound Orders Hub',
        referenceName: 'custom_outbound_orders_hub',
        component: app_custom_outbound_orders_hubComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_outbound_orders_hubDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_outbound_orders_hub');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_outbound_orders_hubComponent,
      'Outbound Orders Hub',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'custom_inventory_transaction_options_form') {
      const title = 'Inventory Transaction Export Options';
      const component = app_custom_inventory_transaction_options_formComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lot_creation_form') {
      this.logger.log('app', 'custom_lot_creation_form');
      const title = 'Create Lot';
      const component = app_custom_lot_creation_formComponent;
      const inParams:{ materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number } = { materialId: null, lotLookupCode: null, vendorLotLookupCode: null, vendorLotId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('lotLookupCode'))) {
        const paramValueString = params.get('lotLookupCode');
        // TODO: date
        inParams.lotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotLookupCode'))) {
        const paramValueString = params.get('vendorLotLookupCode');
        // TODO: date
        inParams.vendorLotLookupCode = paramValueString;
              }
      if (!isNil(params.get('vendorLotId'))) {
        const paramValueString = params.get('vendorLotId');
        inParams.vendorLotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_material_creation_form') {
      this.logger.log('app', 'custom_material_creation_form');
      const title = 'Create Material';
      const component = app_custom_material_creation_formComponent;
      const inParams:{ projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orders_update_form') {
      this.logger.log('app', 'custom_outbound_orders_update_form');
      const title = 'Update Orders';
      const component = app_custom_outbound_orders_update_formComponent;
      const inParams:{ orders: { orderId?: number, shipmentId?: number }[] } = { orders: [] };
      if (!isNil(params.get('orders'))) {
        const paramValueString = params.get('orders');
        inParams.orders = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_location_editor') {
      this.logger.log('app', 'custom_location_editor');
      const title = 'Edit Location';
      const component = app_custom_location_editorComponent;
      const inParams:{ locationId: number } = { locationId: null };
      if (!isNil(params.get('locationId'))) {
        const paramValueString = params.get('locationId');
        inParams.locationId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_lot_editor') {
      this.logger.log('app', 'custom_lot_editor');
      const title = 'Edit Lot';
      const component = app_custom_lot_editorComponent;
      const inParams:{ lotId: number } = { lotId: null };
      if (!isNil(params.get('lotId'))) {
        const paramValueString = params.get('lotId');
        inParams.lotId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_project_editor') {
      this.logger.log('app', 'custom_project_editor');
      const title = 'Edit Project';
      const component = app_custom_project_editorComponent;
      const inParams:{ projectId: number } = { projectId: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_editor') {
      this.logger.log('app', 'custom_sales_order_editor');
      const title = 'Edit Sales Order';
      const component = app_custom_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_contact_addresses_grid') {
      this.logger.log('app', 'custom_contact_addresses_grid');
      const title = 'Addresses';
      const component = app_custom_contact_addresses_gridComponent;
      const inParams:{ entity: string, entityId: number, typeIds: number[] } = { entity: null, entityId: null, typeIds: [] };
      if (!isNil(params.get('entity'))) {
        const paramValueString = params.get('entity');
        // TODO: date
        inParams.entity = paramValueString;
              }
      if (!isNil(params.get('entityId'))) {
        const paramValueString = params.get('entityId');
        inParams.entityId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_excel_sales_order_tracking_import_grid') {
      const title = 'Tracking Import';
      const component = app_custom_excel_sales_order_tracking_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_transactions_grid') {
      const title = 'Transactions';
      const component = app_custom_inventory_transactions_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, materialIds?: number[], operationCodeIds?: number[] } = { ownerId: null, projectId: null, warehouseId: null, createdFrom: null, createdTo: null, materialIds: [], operationCodeIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('createdFrom'))) {
        const paramValueString = params.get('createdFrom');
        // TODO: date
        inParams.createdFrom = paramValueString;
              }
      if (!isNil(params.get('createdTo'))) {
        const paramValueString = params.get('createdTo');
        // TODO: date
        inParams.createdTo = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      this.logger.log('app', 'custom_materials_udf_grid');
      const title = 'Custom Materials UDF';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_material_scanned_grid') {
      const title = 'Scanned barcodes';
      const component = app_custom_outbound_material_scanned_gridComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orders_grid') {
      this.logger.log('app', 'custom_outbound_orders_grid');
      const title = 'Outbound orders';
      const component = app_custom_outbound_orders_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, warehouseId?: number[], statusIds?: number[], countries?: string[], carrierId?: number, carrierServiceId?: number, reference?: string, materialIds?: number[], waveId?: number } = { ownerId: null, projectId: null, warehouseId: [], statusIds: [], countries: [], carrierId: null, carrierServiceId: null, reference: null, materialIds: [], waveId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('countries'))) {
        const paramValueString = params.get('countries');
        inParams.countries = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('carrierId'))) {
        const paramValueString = params.get('carrierId');
        inParams.carrierId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('carrierServiceId'))) {
        const paramValueString = params.get('carrierServiceId');
        inParams.carrierServiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('reference'))) {
        const paramValueString = params.get('reference');
        // TODO: date
        inParams.reference = paramValueString;
              }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_lines_grid') {
      this.logger.log('app', 'custom_purchase_order_lines_grid');
      const title = 'Order Lines';
      const component = app_custom_purchase_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_lines_grid') {
      this.logger.log('app', 'custom_sales_order_lines_grid');
      const title = 'Order lines';
      const component = app_custom_sales_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number, warehouseId: number, orderState?: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null, warehouseId: null, orderState: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderState'))) {
        const paramValueString = params.get('orderState');
        inParams.orderState = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inventory_transactions_hub') {
      const title = 'Inventory Transactions Hub';
      const component = app_custom_inventory_transactions_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_orders_hub') {
      this.logger.log('app', 'custom_outbound_orders_hub');
      const title = 'Outbound Orders Hub';
      const component = app_custom_outbound_orders_hubComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inbound_procedure_codes_single') {
      const title = 'Inbound Procedure Codes';
      const component = app_custom_inbound_procedure_codes_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_operationcodes_dd_single') {
      const title = 'Operation Codes';
      const component = app_custom_operationcodes_dd_singleComponent;
      const inParams:{ operationCodeIds: number[] } = { operationCodeIds: [] };
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_procedure_codes_single') {
      const title = 'Outbound Procedure Codes';
      const component = app_custom_outbound_procedure_codes_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_inbound_procedure_codes_multi') {
      const title = 'Inbound Procedure Codes';
      const component = app_custom_inbound_procedure_codes_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_operationcodes_dd_multi') {
      const title = 'Operation Codes';
      const component = app_custom_operationcodes_dd_multiComponent;
      const inParams:{ operationCodeIds: number[] } = { operationCodeIds: [] };
      if (!isNil(params.get('operationCodeIds'))) {
        const paramValueString = params.get('operationCodeIds');
        inParams.operationCodeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_outbound_procedure_codes_multi') {
      const title = 'Outbound Procedure Codes';
      const component = app_custom_outbound_procedure_codes_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.EasyPost.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ShipTheory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Manifesting.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
