import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_work_class_operation_codes_gridComponent } from './FootPrintManager.work_class_operation_codes_grid.component';
import { FootPrintManager_work_class_users_gridComponent } from './FootPrintManager.work_class_users_grid.component';
import { LaborManagement_equipment_types_dd_multiComponent } from './LaborManagement.equipment_types_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_work_class_operation_codes_gridComponent),
    forwardRef(() => FootPrintManager_work_class_users_gridComponent),
    forwardRef(() => LaborManagement_equipment_types_dd_multiComponent),
  ],
  selector: 'FootPrintManager-work_class_editor',
  templateUrl: './FootPrintManager.work_class_editor.component.html'
})
export class FootPrintManager_work_class_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { workClassId: number } = { workClassId: null };
  //#region Inputs
  @Input('workClassId') set $inParams_workClassId(v: number) {
    this.inParams.workClassId = v;
  }
  get $inParams_workClassId(): number {
    return this.inParams.workClassId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { isDeleted?: boolean, isConfirmed?: boolean, promises?: any[] } = { isDeleted: null, isConfirmed: null, promises: [] };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { cachedOperationCodes?: { id?: number, priority?: number, name?: string }[], cachedUsers?: { id?: string, displayName?: string }[], operationCodes?: { id?: number, priority?: number, name?: string }[], users?: { id?: string, displayName?: string }[] } = { };
  //#endregion
  entity: { Id?: number, Description?: string, LookupCode?: string, Name?: string, Priority?: number, OperationCodes?: { Id?: number, Priority?: number, OperationCode?: { Id?: number, Name?: string } }[], EquipmentTypes?: { Id?: number, EquipmentType?: { Id?: number, LookupCode?: string } }[], AssignmentListForWorkClass?: { UserId?: string }[] };

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    priority: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    assign_equipment_types: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    equipment_types: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      save_and_close: new ToolModel(new ButtonModel('save_and_close', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular')
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Work class code', true)
,
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', true)
,
    priority: new FieldModel(new NumberBoxModel(this.formGroup.controls['priority'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Priority', false)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', false)
,
    assign_equipment_types: new FieldModel(new CheckBoxModel(this.formGroup.controls['assign_equipment_types'] as DatexFormControl, null, false, 'Assign equipment')
, new ControlContainerStyles(null, null), '', false)
,
    equipment_types: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['equipment_types'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Equipment', false)
,
  };

  fieldsets = {
  general: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      operation_codes: new TabItemModel(
        this.rootTabGroup, 
        'Operations', 
        ),
      users: new TabItemModel(
        this.rootTabGroup, 
        'Users', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_operation_codes_work_class_operation_codes_grid_inParams_workClassId(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.entity.Id;
      
      return expr;
    }
  
    cacheValueFor_$tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes: any;
    get $tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes(): any {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.operationCodes;
      
      if(!isEqual(this.cacheValueFor_$tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes, expr)) {
        this.cacheValueFor_$tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes = expr;
      }
      return this.cacheValueFor_$tabs_operation_codes_work_class_operation_codes_grid_inParams_operationCodes;
    }
  
    cacheValueFor_$tabs_users_work_class_users_grid_inParams_users: any[];
    get $tabs_users_work_class_users_grid_inParams_users(): any[] {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.vars.users;
      
      if(!isEqual(this.cacheValueFor_$tabs_users_work_class_users_grid_inParams_users, expr)) {
        this.cacheValueFor_$tabs_users_work_class_users_grid_inParams_users = expr;
      }
      return this.cacheValueFor_$tabs_users_work_class_users_grid_inParams_users;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_operation_codes', { read: FootPrintManager_work_class_operation_codes_gridComponent }) $tabs_operation_codes: FootPrintManager_work_class_operation_codes_gridComponent;
      @ViewChild('$tabs_users', { read: FootPrintManager_work_class_users_gridComponent }) $tabs_users: FootPrintManager_work_class_users_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    priority: this.fields.priority.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    assign_equipment_types: this.fields.assign_equipment_types.control.valueChanges
    ,
    equipment_types: this.fields.equipment_types.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.operation_codes,
      this.tabs.users,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.workClassId)) {
        this.$missingRequiredInParams.push('workClassId');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    this.title = 'Work class';
    
    await this.on_init();
    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      workClassId:  $editor.inParams.workClassId 
    };

    const data = await this.datasources.LaborManagement.ds_work_class_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.priority.control as NumberBoxModel).reset($editor.entity.Priority);
    (this.fields.description.control as TextBoxModel).reset($editor.entity.Description);
    (this.fields.assign_equipment_types.control as CheckBoxModel).reset($editor.entity.EquipmentTypes.length > 0);
    (this.fields.equipment_types.control as SelectBoxModel).reset($editor.entity.EquipmentTypes.map(e => e.EquipmentType.Id));

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_operation_codes') {
        if (!isNil(this.$tabs_operation_codes) && !this.tabs.operation_codes.hidden) {
          this.$tabs_operation_codes.refresh(true, false, null);
        }
      }    
      if (childToSkip !== '$tabs_users') {
        if (!isNil(this.$tabs_users) && !this.tabs.users.hidden) {
          this.$tabs_users.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_field_changed();
      });
    this.$formGroupFieldValidationObservables
      .name
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_field_changed();
      });
    this.$formGroupFieldValidationObservables
      .priority
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_field_changed();
      });
    this.$formGroupFieldValidationObservables
      .assign_equipment_types
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_assign_equipment_types_changed();
      });
    this.$formGroupFieldValidationObservables
      .equipment_types
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_field_changed();
      });
  }

  //#region private flows
  on_save_clicked(event = null) {
    return this.on_save_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_clickedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_save_clicked');
  $editor.toolbar.save_and_close.control.readOnly = true;
  //$editor.toolbar.delete_work_class.control.readOnly = true;
  $editor.toolbar.cancel.control.readOnly = true;
  try {
      await checkRequiredFields();
  
      await new Promise(resolve => setTimeout(resolve, 50));
  
      $editor.outParams.isConfirmed = true;
  
      // Update cached values
      $editor.vars.operationCodes = $editor.vars.cachedOperationCodes;
      $editor.vars.users = $editor.vars.cachedUsers;
  
      $editor.outParams.promises.push(updateOperationCodes());
  
      $editor.outParams.promises.push(updateEquipmentTypes());
  
      $editor.outParams.promises.push(updateAssignedUsers());
  
      $editor.outParams.promises.push($flows.Utilities.crud_update_flow({
          entitySet: 'WorkClasses',
          id: $editor.entity.Id,
          entity: {
              LookupCode: $editor.fields.lookupcode.control.value,
              Name: $editor.fields.name.control.value,
              Description: $editor.fields.description.control.value,
              Priority: $editor.fields.priority.control.value
          }
      }));
  
      $editor.close();
  
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.LaborManagement.openErrorDialog('Error saving work class', targetError.message);
  }
  
  $editor.toolbar.save_and_close.control.readOnly = false;
  //$editor.toolbar.delete_work_class.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  
  /*****************************************
   * FUNCTIONS
  ******************************************/
  async function updateOperationCodes() {
      /**** Set operation codes ****/
      let currentOperationCodes = $editor.entity.OperationCodes;
  
      // Create
      let operationCodesToCreate = $editor.vars.operationCodes?.filter(oc => currentOperationCodes.map(co => co.OperationCode.Id).indexOf(oc.id) < 0);
  
      for (let operationCode of operationCodesToCreate) {
          await $flows.Utilities.crud_create_flow({
              entitySet: 'WorkClassOperationCodes',
              entity: {
                  OperationCodeId: operationCode.id,
                  Priority: operationCode.priority,
                  WorkClassId: $editor.entity.Id
              }
          });
      }
  
      // Delete
      let operationCodesToDelete = currentOperationCodes?.filter(co => $editor.vars.operationCodes.map(oc => oc.id).indexOf(co.OperationCode.Id) < 0);
  
      for (let operationCode of operationCodesToDelete) {
          await $flows.Utilities.crud_delete_flow({
              entitySet: 'WorkClassOperationCodes',
              id: operationCode.Id
          });
      }
  
      // Update
      let operationCodesToUpdate = currentOperationCodes?.filter(co => $editor.vars.operationCodes.map(oc => oc.id).indexOf(co.OperationCode.Id) >= 0);
  
      for (let operationCode of operationCodesToUpdate) {
          await $flows.Utilities.crud_update_flow({
              entitySet: 'WorkClassOperationCodes',
              id: operationCode.Id,
              entity: {
                  Priority: $editor.vars.operationCodes.find(oc => oc.id === operationCode.OperationCode.Id).priority
              }
          });
      }
  }
  
  async function updateEquipmentTypes() {
      /**** Set equipment types ****/
      let currentEquipmentTypeIds = $editor.entity.EquipmentTypes?.map(e => e.EquipmentType.Id);
      let newEquipmentTypeIds: number[] = $editor.fields.assign_equipment_types.control.value ? $editor.fields.equipment_types.control.value : [];
  
      // Create
      let createEquipmentTypeAssignmentIds = newEquipmentTypeIds?.filter((equipmentId: number) => currentEquipmentTypeIds.indexOf(equipmentId) < 0);
  
      for (let equipmentTypeId of createEquipmentTypeAssignmentIds) {
          await $flows.Utilities.crud_create_flow({
              entitySet: 'WorkClassEquipmentTypes',
              entity: {
                  EquipmentTypeId: equipmentTypeId,
                  WorkClassId: $editor.entity.Id
              }
          });
      }
  
      // Delete
      let deleteEquipmentTypeAssignmentIds = currentEquipmentTypeIds.filter(id => newEquipmentTypeIds?.indexOf(id) < 0);
  
      for (let equipmentTypeAssignmentId of deleteEquipmentTypeAssignmentIds) {
          await $flows.Utilities.crud_delete_flow({
              entitySet: 'WorkClassEquipmentTypes',
              id: $editor.entity.EquipmentTypes.find(et => et.EquipmentType.Id === equipmentTypeAssignmentId).Id
          });
      }
  }
  
  async function updateAssignedUsers() {
      /**** Set equipment types ****/
      let currentUserIds = $editor.entity.AssignmentListForWorkClass?.map(u => u.UserId);
      let newUserIds = $editor.vars.users.filter(user => currentUserIds.indexOf(user.id) < 0).map(user => user.id);
      let deleteUserIds = currentUserIds.filter(userId => !$editor.vars.users.find(u => u.id === userId));
  
      // Create
      for (let userId of newUserIds) {
          let result = (await $flows.LaborManagement.assign_user_to_work_class_flow({
              workClassId: $editor.entity.Id,
              userId: userId
          }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
          }
      }
  
      // Delete
      for (let userId of deleteUserIds) {
          let result = (await $flows.LaborManagement.unassign_user_from_work_class_flow({
              workClassId: $editor.entity.Id,
              userId: userId
          }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons})).formattedValue);
          }
      }
  }
  
  async function checkRequiredFields() {
      let missingFields: string[] = [];
  
      for (let key in $editor.fields) {
          if (!$utils.isDefined($editor.fields[key].control.value) && $editor.fields[key].required) {
              missingFields.push(`'${$editor.fields[key].label}'`);
          }
      }
  
      if ($utils.isDefined(missingFields)) {
          throw new Error(`Missing required field${missingFields.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: missingFields})).formattedValue}.`);
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_cancel_clicked');
  // Close the editor without updating entity
  $editor.close();
  
  }
  on_delete_work_class_clicked(event = null) {
    return this.on_delete_work_class_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_work_class_clickedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_delete_work_class_clicked');
  $editor.toolbar.save_and_close.control.readOnly = true;
  //$editor.toolbar.delete_work_class.control.readOnly = true;
  $editor.toolbar.cancel.control.readOnly = true;
  
  try {
      let userConfirmed = (await $shell.LaborManagement.openConfirmationDialog('Delete work class', `Are you sure you want to delete work class ${$editor.entity.LookupCode}? Once deleted it cannot be recovered.`, 'Confirm', 'Cancel'));
  
      if (userConfirmed) {
          $editor.outParams.isDeleted = true;
  
          await $flows.LaborManagement.delete_work_class_flow({ workClassId: $editor.entity.Id });
  
          $editor.close();
      }
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.LaborManagement.openErrorDialog('Error saving work class', targetError.message);
  }
  
  $editor.toolbar.save_and_close.control.readOnly = false;
  //$editor.toolbar.delete_work_class.control.readOnly = false;
  $editor.toolbar.cancel.control.readOnly = false;
  }
  on_operation_codes_changed(event = null) {
    return this.on_operation_codes_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_operation_codes_changedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_operation_codes_changed');
  $editor.vars.cachedOperationCodes = $event.operationCodes;
  $editor.set_state();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_init');
  $editor.vars.operationCodes = [];
  $editor.outParams.promises = [];
  
  $editor.outParams.isConfirmed = false;
  $editor.outParams.isDeleted = false;
  
  $editor.fields.equipment_types.hidden = true;
  }
  on_field_changed(event = null) {
    return this.on_field_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_field_changedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_field_changed');
  $editor.set_state();
  }
  set_state(event = null) {
    return this.set_stateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_stateInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.set_state');
  const USE_MOD_CHECK = false;
  
  $editor.title = $editor.entity.LookupCode;
  
  if ($editor.fields.assign_equipment_types.control.value) {
      $editor.fields.equipment_types.hidden = false;
  }
  else {
      $editor.fields.equipment_types.hidden = true;
  }
  
  if (USE_MOD_CHECK) {
      if ($utils.isDefined($editor.entity)) {
          // Check if operation codes changed
          let operationCodesChanged = false;
          operationCodesChanged = $editor.vars.operationCodes.sort((a, b) => a.id - b.id).map(oc => `${oc.priority}:${oc.id}`).join(',') !== $editor.entity.OperationCodes.sort((a, b) => a.OperationCode.Id - b.OperationCode.Id).map(oc => `${oc.Priority}:${oc.OperationCode.Id}`).join(',')
  
          if (
              $editor.fields.lookupcode.control.value !== $editor.entity.LookupCode ||
              $editor.fields.name.control.value !== $editor.entity.Name ||
              $editor.fields.description.control.value !== $editor.entity.Description ||
              $editor.fields.priority.control.value !== $editor.entity.Priority ||
              operationCodesChanged ||
              $editor.fields.equipment_types.control.value.sort().join(',') !== $editor.entity.EquipmentTypes.map(e => e.EquipmentType.Id).sort().join(',')
          ) {
              // Has been modified
              $editor.toolbar.save_and_close.control.readOnly = false;
              $editor.toolbar.save_and_close.control.styles.setPrimaryClass();
          }
          else {
              // Has not been modified
              $editor.toolbar.save_and_close.control.readOnly = true;
              $editor.toolbar.save_and_close.control.styles.setTertiaryClass();
          }
      }
      else {
          $editor.toolbar.save_and_close.control.readOnly = true;
          $editor.toolbar.save_and_close.control.styles.setTertiaryClass();
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_data_loaded');
  $editor.set_state();
  
  let users = (await $datasources.LaborManagement.ds_get_users_info.getByKeys({$keys: $editor.entity.AssignmentListForWorkClass.map(u => u.UserId)})).result;
  
  $editor.vars.operationCodes = $editor.entity.OperationCodes.map(oc => ({ id: oc.OperationCode.Id, priority: oc.Priority, name: oc.OperationCode.Name }));
  $editor.vars.users = users.map(user => ({ id: user.id, displayName: user.displayName }));
  
  $editor.vars.cachedOperationCodes = $editor.vars.operationCodes;
  $editor.vars.cachedUsers = $editor.vars.users;
  }
  on_assign_equipment_types_changed(event = null) {
    return this.on_assign_equipment_types_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_equipment_types_changedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_assign_equipment_types_changed');
  if ($editor.fields.assign_equipment_types.control.value) {
      $editor.fields.equipment_types.hidden = false;
  }
  else {
      $editor.fields.equipment_types.hidden = true;
  }
  }
  on_users_changed(event = null) {
    return this.on_users_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_users_changedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_users_changed');
  $editor.vars.cachedUsers = $event.users;
  $editor.set_state();
  }
  on_operation_codes_initialized(event = null) {
    return this.on_operation_codes_initializedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_operation_codes_initializedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_operation_codes_initialized');
  if ($editor.vars.users !== $editor.vars.cachedUsers) {
      $editor.vars.users = $editor.vars.cachedUsers;
  }
  }
  on_users_initialized(event = null) {
    return this.on_users_initializedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_users_initializedInternal(
    $editor: FootPrintManager_work_class_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'work_class_editor.on_users_initialized');
  if ($editor.vars.operationCodes !== $editor.vars.cachedOperationCodes) {
      $editor.vars.operationCodes = $editor.vars.cachedOperationCodes;
  }
  }
  //#endregion private flows
}
