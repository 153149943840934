import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { EasyPost_DatasourceService } from './EasyPost.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { ShipTheory_DatasourceService } from './ShipTheory.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Manifesting_DatasourceService } from './Manifesting.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';

import { app_custom_ds_contact_addresses_gridService } from './app.datasource.index';
import { app_custom_ds_excel_sales_order_tracking_import_gridService } from './app.datasource.index';
import { app_custom_ds_export_duty_vendorlotsService } from './app.datasource.index';
import { app_custom_ds_get_buyer_address_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_child_orderlines_by_orderId_lineNumberService } from './app.datasource.index';
import { app_custom_ds_get_currency_by_idService } from './app.datasource.index';
import { app_custom_ds_get_currency_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_grouped_child_orderlines_by_orderId_lineNumberService } from './app.datasource.index';
import { app_custom_ds_get_lot_original_inbound_orderService } from './app.datasource.index';
import { app_custom_ds_get_material_details_by_materialIdService } from './app.datasource.index';
import { app_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service } from './app.datasource.index';
import { app_custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedIdService } from './app.datasource.index';
import { app_custom_ds_get_next_orderline_numberService } from './app.datasource.index';
import { app_custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseIdService } from './app.datasource.index';
import { app_custom_ds_get_order_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_owner_contact_by_typeIdService } from './app.datasource.index';
import { app_custom_ds_get_order_project_contact_by_typeIdService } from './app.datasource.index';
import { app_custom_ds_get_picked_content_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_sales_order_by_owner_referenceService } from './app.datasource.index';
import { app_custom_ds_get_sales_order_lines_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_shipment_and_order_from_lookupCodeService } from './app.datasource.index';
import { app_custom_ds_get_shiptheory_items_contents_by_shipmentService } from './app.datasource.index';
import { app_custom_ds_get_third_party_address_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCodeService } from './app.datasource.index';
import { app_custom_ds_get_upper_packaging_by_materialIdService } from './app.datasource.index';
import { app_custom_ds_inventory_availability_by_materialId_packagingId_warehouseIdService } from './app.datasource.index';
import { app_custom_ds_inventory_transactions_gridService } from './app.datasource.index';
import { app_custom_ds_location_editorService } from './app.datasource.index';
import { app_custom_ds_lot_editorService } from './app.datasource.index';
import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_custom_ds_operationcodes_ddService } from './app.datasource.index';
import { app_custom_ds_outbound_material_scanned_gridService } from './app.datasource.index';
import { app_custom_ds_outbound_orders_details_exportService } from './app.datasource.index';
import { app_custom_ds_outbound_orders_exportService } from './app.datasource.index';
import { app_custom_ds_outbound_requires_scanningService } from './app.datasource.index';
import { app_custom_ds_project_editorService } from './app.datasource.index';
import { app_custom_ds_purchase_order_lines_gridService } from './app.datasource.index';
import { app_custom_ds_sales_order_editorService } from './app.datasource.index';
import { app_custom_ds_sales_order_lines_gridService } from './app.datasource.index';
import { app_custom_get_project_tax_identifiers_by_orderIdService } from './app.datasource.index';
import { app_ds_get_owner_logo_attachmentService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.EasyPost = this.injector.get(EasyPost_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.ShipTheory = this.injector.get(ShipTheory_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.Manifesting = this.injector.get(Manifesting_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public Materials: Materials_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public EasyPost: EasyPost_DatasourceService;
    public Lots: Lots_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public Reports: Reports_DatasourceService;
    public ShipTheory: ShipTheory_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public Manifesting: Manifesting_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_contact_addresses_grid: app_custom_ds_contact_addresses_gridService;
  public get custom_ds_contact_addresses_grid(): app_custom_ds_contact_addresses_gridService {
    if(!this._custom_ds_contact_addresses_grid) {
      this._custom_ds_contact_addresses_grid = this.injector.get(app_custom_ds_contact_addresses_gridService);
    }
    return this._custom_ds_contact_addresses_grid;
  }
  private _custom_ds_excel_sales_order_tracking_import_grid: app_custom_ds_excel_sales_order_tracking_import_gridService;
  public get custom_ds_excel_sales_order_tracking_import_grid(): app_custom_ds_excel_sales_order_tracking_import_gridService {
    if(!this._custom_ds_excel_sales_order_tracking_import_grid) {
      this._custom_ds_excel_sales_order_tracking_import_grid = this.injector.get(app_custom_ds_excel_sales_order_tracking_import_gridService);
    }
    return this._custom_ds_excel_sales_order_tracking_import_grid;
  }
  private _custom_ds_export_duty_vendorlots: app_custom_ds_export_duty_vendorlotsService;
  public get custom_ds_export_duty_vendorlots(): app_custom_ds_export_duty_vendorlotsService {
    if(!this._custom_ds_export_duty_vendorlots) {
      this._custom_ds_export_duty_vendorlots = this.injector.get(app_custom_ds_export_duty_vendorlotsService);
    }
    return this._custom_ds_export_duty_vendorlots;
  }
  private _custom_ds_get_buyer_address_by_orderId: app_custom_ds_get_buyer_address_by_orderIdService;
  public get custom_ds_get_buyer_address_by_orderId(): app_custom_ds_get_buyer_address_by_orderIdService {
    if(!this._custom_ds_get_buyer_address_by_orderId) {
      this._custom_ds_get_buyer_address_by_orderId = this.injector.get(app_custom_ds_get_buyer_address_by_orderIdService);
    }
    return this._custom_ds_get_buyer_address_by_orderId;
  }
  private _custom_ds_get_child_orderlines_by_orderId_lineNumber: app_custom_ds_get_child_orderlines_by_orderId_lineNumberService;
  public get custom_ds_get_child_orderlines_by_orderId_lineNumber(): app_custom_ds_get_child_orderlines_by_orderId_lineNumberService {
    if(!this._custom_ds_get_child_orderlines_by_orderId_lineNumber) {
      this._custom_ds_get_child_orderlines_by_orderId_lineNumber = this.injector.get(app_custom_ds_get_child_orderlines_by_orderId_lineNumberService);
    }
    return this._custom_ds_get_child_orderlines_by_orderId_lineNumber;
  }
  private _custom_ds_get_currency_by_id: app_custom_ds_get_currency_by_idService;
  public get custom_ds_get_currency_by_id(): app_custom_ds_get_currency_by_idService {
    if(!this._custom_ds_get_currency_by_id) {
      this._custom_ds_get_currency_by_id = this.injector.get(app_custom_ds_get_currency_by_idService);
    }
    return this._custom_ds_get_currency_by_id;
  }
  private _custom_ds_get_currency_by_orderId: app_custom_ds_get_currency_by_orderIdService;
  public get custom_ds_get_currency_by_orderId(): app_custom_ds_get_currency_by_orderIdService {
    if(!this._custom_ds_get_currency_by_orderId) {
      this._custom_ds_get_currency_by_orderId = this.injector.get(app_custom_ds_get_currency_by_orderIdService);
    }
    return this._custom_ds_get_currency_by_orderId;
  }
  private _custom_ds_get_grouped_child_orderlines_by_orderId_lineNumber: app_custom_ds_get_grouped_child_orderlines_by_orderId_lineNumberService;
  public get custom_ds_get_grouped_child_orderlines_by_orderId_lineNumber(): app_custom_ds_get_grouped_child_orderlines_by_orderId_lineNumberService {
    if(!this._custom_ds_get_grouped_child_orderlines_by_orderId_lineNumber) {
      this._custom_ds_get_grouped_child_orderlines_by_orderId_lineNumber = this.injector.get(app_custom_ds_get_grouped_child_orderlines_by_orderId_lineNumberService);
    }
    return this._custom_ds_get_grouped_child_orderlines_by_orderId_lineNumber;
  }
  private _custom_ds_get_lot_original_inbound_order: app_custom_ds_get_lot_original_inbound_orderService;
  public get custom_ds_get_lot_original_inbound_order(): app_custom_ds_get_lot_original_inbound_orderService {
    if(!this._custom_ds_get_lot_original_inbound_order) {
      this._custom_ds_get_lot_original_inbound_order = this.injector.get(app_custom_ds_get_lot_original_inbound_orderService);
    }
    return this._custom_ds_get_lot_original_inbound_order;
  }
  private _custom_ds_get_material_details_by_materialId: app_custom_ds_get_material_details_by_materialIdService;
  public get custom_ds_get_material_details_by_materialId(): app_custom_ds_get_material_details_by_materialIdService {
    if(!this._custom_ds_get_material_details_by_materialId) {
      this._custom_ds_get_material_details_by_materialId = this.injector.get(app_custom_ds_get_material_details_by_materialIdService);
    }
    return this._custom_ds_get_material_details_by_materialId;
  }
  private _custom_ds_get_material_packaging_by_materialId_and_packagingId_top1: app_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service;
  public get custom_ds_get_material_packaging_by_materialId_and_packagingId_top1(): app_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service {
    if(!this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1) {
      this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1 = this.injector.get(app_custom_ds_get_material_packaging_by_materialId_and_packagingId_top1Service);
    }
    return this._custom_ds_get_material_packaging_by_materialId_and_packagingId_top1;
  }
  private _custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedId: app_custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedIdService;
  public get custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedId(): app_custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedIdService {
    if(!this._custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedId) {
      this._custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedId = this.injector.get(app_custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedIdService);
    }
    return this._custom_ds_get_material_soft_allocations_by_materialId_warehouseId_packagedId;
  }
  private _custom_ds_get_next_orderline_number: app_custom_ds_get_next_orderline_numberService;
  public get custom_ds_get_next_orderline_number(): app_custom_ds_get_next_orderline_numberService {
    if(!this._custom_ds_get_next_orderline_number) {
      this._custom_ds_get_next_orderline_number = this.injector.get(app_custom_ds_get_next_orderline_numberService);
    }
    return this._custom_ds_get_next_orderline_number;
  }
  private _custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseId: app_custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseIdService;
  public get custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseId(): app_custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseIdService {
    if(!this._custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseId) {
      this._custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseId = this.injector.get(app_custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseIdService);
    }
    return this._custom_ds_get_not_eligible_inventory_by_material_packagedId_warehouseId;
  }
  private _custom_ds_get_order_by_orderId: app_custom_ds_get_order_by_orderIdService;
  public get custom_ds_get_order_by_orderId(): app_custom_ds_get_order_by_orderIdService {
    if(!this._custom_ds_get_order_by_orderId) {
      this._custom_ds_get_order_by_orderId = this.injector.get(app_custom_ds_get_order_by_orderIdService);
    }
    return this._custom_ds_get_order_by_orderId;
  }
  private _custom_ds_get_order_owner_contact_by_typeId: app_custom_ds_get_order_owner_contact_by_typeIdService;
  public get custom_ds_get_order_owner_contact_by_typeId(): app_custom_ds_get_order_owner_contact_by_typeIdService {
    if(!this._custom_ds_get_order_owner_contact_by_typeId) {
      this._custom_ds_get_order_owner_contact_by_typeId = this.injector.get(app_custom_ds_get_order_owner_contact_by_typeIdService);
    }
    return this._custom_ds_get_order_owner_contact_by_typeId;
  }
  private _custom_ds_get_order_project_contact_by_typeId: app_custom_ds_get_order_project_contact_by_typeIdService;
  public get custom_ds_get_order_project_contact_by_typeId(): app_custom_ds_get_order_project_contact_by_typeIdService {
    if(!this._custom_ds_get_order_project_contact_by_typeId) {
      this._custom_ds_get_order_project_contact_by_typeId = this.injector.get(app_custom_ds_get_order_project_contact_by_typeIdService);
    }
    return this._custom_ds_get_order_project_contact_by_typeId;
  }
  private _custom_ds_get_picked_content_by_orderId: app_custom_ds_get_picked_content_by_orderIdService;
  public get custom_ds_get_picked_content_by_orderId(): app_custom_ds_get_picked_content_by_orderIdService {
    if(!this._custom_ds_get_picked_content_by_orderId) {
      this._custom_ds_get_picked_content_by_orderId = this.injector.get(app_custom_ds_get_picked_content_by_orderIdService);
    }
    return this._custom_ds_get_picked_content_by_orderId;
  }
  private _custom_ds_get_sales_order_by_owner_reference: app_custom_ds_get_sales_order_by_owner_referenceService;
  public get custom_ds_get_sales_order_by_owner_reference(): app_custom_ds_get_sales_order_by_owner_referenceService {
    if(!this._custom_ds_get_sales_order_by_owner_reference) {
      this._custom_ds_get_sales_order_by_owner_reference = this.injector.get(app_custom_ds_get_sales_order_by_owner_referenceService);
    }
    return this._custom_ds_get_sales_order_by_owner_reference;
  }
  private _custom_ds_get_sales_order_lines_by_orderId: app_custom_ds_get_sales_order_lines_by_orderIdService;
  public get custom_ds_get_sales_order_lines_by_orderId(): app_custom_ds_get_sales_order_lines_by_orderIdService {
    if(!this._custom_ds_get_sales_order_lines_by_orderId) {
      this._custom_ds_get_sales_order_lines_by_orderId = this.injector.get(app_custom_ds_get_sales_order_lines_by_orderIdService);
    }
    return this._custom_ds_get_sales_order_lines_by_orderId;
  }
  private _custom_ds_get_shipment_and_order_from_lookupCode: app_custom_ds_get_shipment_and_order_from_lookupCodeService;
  public get custom_ds_get_shipment_and_order_from_lookupCode(): app_custom_ds_get_shipment_and_order_from_lookupCodeService {
    if(!this._custom_ds_get_shipment_and_order_from_lookupCode) {
      this._custom_ds_get_shipment_and_order_from_lookupCode = this.injector.get(app_custom_ds_get_shipment_and_order_from_lookupCodeService);
    }
    return this._custom_ds_get_shipment_and_order_from_lookupCode;
  }
  private _custom_ds_get_shiptheory_items_contents_by_shipment: app_custom_ds_get_shiptheory_items_contents_by_shipmentService;
  public get custom_ds_get_shiptheory_items_contents_by_shipment(): app_custom_ds_get_shiptheory_items_contents_by_shipmentService {
    if(!this._custom_ds_get_shiptheory_items_contents_by_shipment) {
      this._custom_ds_get_shiptheory_items_contents_by_shipment = this.injector.get(app_custom_ds_get_shiptheory_items_contents_by_shipmentService);
    }
    return this._custom_ds_get_shiptheory_items_contents_by_shipment;
  }
  private _custom_ds_get_third_party_address_by_orderId: app_custom_ds_get_third_party_address_by_orderIdService;
  public get custom_ds_get_third_party_address_by_orderId(): app_custom_ds_get_third_party_address_by_orderIdService {
    if(!this._custom_ds_get_third_party_address_by_orderId) {
      this._custom_ds_get_third_party_address_by_orderId = this.injector.get(app_custom_ds_get_third_party_address_by_orderIdService);
    }
    return this._custom_ds_get_third_party_address_by_orderId;
  }
  private _custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCode: app_custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCodeService;
  public get custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCode(): app_custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCodeService {
    if(!this._custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCode) {
      this._custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCode = this.injector.get(app_custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCodeService);
    }
    return this._custom_ds_get_total_ordered_amount_by_orderId_projectId_materialLookupCode;
  }
  private _custom_ds_get_upper_packaging_by_materialId: app_custom_ds_get_upper_packaging_by_materialIdService;
  public get custom_ds_get_upper_packaging_by_materialId(): app_custom_ds_get_upper_packaging_by_materialIdService {
    if(!this._custom_ds_get_upper_packaging_by_materialId) {
      this._custom_ds_get_upper_packaging_by_materialId = this.injector.get(app_custom_ds_get_upper_packaging_by_materialIdService);
    }
    return this._custom_ds_get_upper_packaging_by_materialId;
  }
  private _custom_ds_inventory_availability_by_materialId_packagingId_warehouseId: app_custom_ds_inventory_availability_by_materialId_packagingId_warehouseIdService;
  public get custom_ds_inventory_availability_by_materialId_packagingId_warehouseId(): app_custom_ds_inventory_availability_by_materialId_packagingId_warehouseIdService {
    if(!this._custom_ds_inventory_availability_by_materialId_packagingId_warehouseId) {
      this._custom_ds_inventory_availability_by_materialId_packagingId_warehouseId = this.injector.get(app_custom_ds_inventory_availability_by_materialId_packagingId_warehouseIdService);
    }
    return this._custom_ds_inventory_availability_by_materialId_packagingId_warehouseId;
  }
  private _custom_ds_inventory_transactions_grid: app_custom_ds_inventory_transactions_gridService;
  public get custom_ds_inventory_transactions_grid(): app_custom_ds_inventory_transactions_gridService {
    if(!this._custom_ds_inventory_transactions_grid) {
      this._custom_ds_inventory_transactions_grid = this.injector.get(app_custom_ds_inventory_transactions_gridService);
    }
    return this._custom_ds_inventory_transactions_grid;
  }
  private _custom_ds_location_editor: app_custom_ds_location_editorService;
  public get custom_ds_location_editor(): app_custom_ds_location_editorService {
    if(!this._custom_ds_location_editor) {
      this._custom_ds_location_editor = this.injector.get(app_custom_ds_location_editorService);
    }
    return this._custom_ds_location_editor;
  }
  private _custom_ds_lot_editor: app_custom_ds_lot_editorService;
  public get custom_ds_lot_editor(): app_custom_ds_lot_editorService {
    if(!this._custom_ds_lot_editor) {
      this._custom_ds_lot_editor = this.injector.get(app_custom_ds_lot_editorService);
    }
    return this._custom_ds_lot_editor;
  }
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _custom_ds_operationcodes_dd: app_custom_ds_operationcodes_ddService;
  public get custom_ds_operationcodes_dd(): app_custom_ds_operationcodes_ddService {
    if(!this._custom_ds_operationcodes_dd) {
      this._custom_ds_operationcodes_dd = this.injector.get(app_custom_ds_operationcodes_ddService);
    }
    return this._custom_ds_operationcodes_dd;
  }
  private _custom_ds_outbound_material_scanned_grid: app_custom_ds_outbound_material_scanned_gridService;
  public get custom_ds_outbound_material_scanned_grid(): app_custom_ds_outbound_material_scanned_gridService {
    if(!this._custom_ds_outbound_material_scanned_grid) {
      this._custom_ds_outbound_material_scanned_grid = this.injector.get(app_custom_ds_outbound_material_scanned_gridService);
    }
    return this._custom_ds_outbound_material_scanned_grid;
  }
  private _custom_ds_outbound_orders_details_export: app_custom_ds_outbound_orders_details_exportService;
  public get custom_ds_outbound_orders_details_export(): app_custom_ds_outbound_orders_details_exportService {
    if(!this._custom_ds_outbound_orders_details_export) {
      this._custom_ds_outbound_orders_details_export = this.injector.get(app_custom_ds_outbound_orders_details_exportService);
    }
    return this._custom_ds_outbound_orders_details_export;
  }
  private _custom_ds_outbound_orders_export: app_custom_ds_outbound_orders_exportService;
  public get custom_ds_outbound_orders_export(): app_custom_ds_outbound_orders_exportService {
    if(!this._custom_ds_outbound_orders_export) {
      this._custom_ds_outbound_orders_export = this.injector.get(app_custom_ds_outbound_orders_exportService);
    }
    return this._custom_ds_outbound_orders_export;
  }
  private _custom_ds_outbound_requires_scanning: app_custom_ds_outbound_requires_scanningService;
  public get custom_ds_outbound_requires_scanning(): app_custom_ds_outbound_requires_scanningService {
    if(!this._custom_ds_outbound_requires_scanning) {
      this._custom_ds_outbound_requires_scanning = this.injector.get(app_custom_ds_outbound_requires_scanningService);
    }
    return this._custom_ds_outbound_requires_scanning;
  }
  private _custom_ds_project_editor: app_custom_ds_project_editorService;
  public get custom_ds_project_editor(): app_custom_ds_project_editorService {
    if(!this._custom_ds_project_editor) {
      this._custom_ds_project_editor = this.injector.get(app_custom_ds_project_editorService);
    }
    return this._custom_ds_project_editor;
  }
  private _custom_ds_purchase_order_lines_grid: app_custom_ds_purchase_order_lines_gridService;
  public get custom_ds_purchase_order_lines_grid(): app_custom_ds_purchase_order_lines_gridService {
    if(!this._custom_ds_purchase_order_lines_grid) {
      this._custom_ds_purchase_order_lines_grid = this.injector.get(app_custom_ds_purchase_order_lines_gridService);
    }
    return this._custom_ds_purchase_order_lines_grid;
  }
  private _custom_ds_sales_order_editor: app_custom_ds_sales_order_editorService;
  public get custom_ds_sales_order_editor(): app_custom_ds_sales_order_editorService {
    if(!this._custom_ds_sales_order_editor) {
      this._custom_ds_sales_order_editor = this.injector.get(app_custom_ds_sales_order_editorService);
    }
    return this._custom_ds_sales_order_editor;
  }
  private _custom_ds_sales_order_lines_grid: app_custom_ds_sales_order_lines_gridService;
  public get custom_ds_sales_order_lines_grid(): app_custom_ds_sales_order_lines_gridService {
    if(!this._custom_ds_sales_order_lines_grid) {
      this._custom_ds_sales_order_lines_grid = this.injector.get(app_custom_ds_sales_order_lines_gridService);
    }
    return this._custom_ds_sales_order_lines_grid;
  }
  private _custom_get_project_tax_identifiers_by_orderId: app_custom_get_project_tax_identifiers_by_orderIdService;
  public get custom_get_project_tax_identifiers_by_orderId(): app_custom_get_project_tax_identifiers_by_orderIdService {
    if(!this._custom_get_project_tax_identifiers_by_orderId) {
      this._custom_get_project_tax_identifiers_by_orderId = this.injector.get(app_custom_get_project_tax_identifiers_by_orderIdService);
    }
    return this._custom_get_project_tax_identifiers_by_orderId;
  }
  private _ds_get_owner_logo_attachment: app_ds_get_owner_logo_attachmentService;
  public get ds_get_owner_logo_attachment(): app_ds_get_owner_logo_attachmentService {
    if(!this._ds_get_owner_logo_attachment) {
      this._ds_get_owner_logo_attachment = this.injector.get(app_ds_get_owner_logo_attachmentService);
    }
    return this._ds_get_owner_logo_attachment;
  }
}

