import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_material_barcode_reportServiceInParams {
  order_id: number}

interface IReports_custom_material_barcode_reportServiceData {
  odata_barcode?: { result?: { Contents?: { right_side?: { order_id?: number, order_lookup_code?: string, material_id?: number, material_lookup_code?: string, upc_code?: string }[], left_side?: { order_id?: number, order_lookup_code?: string, material_id?: number, material_lookup_code?: string, upc_code?: string }[] } } }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_material_barcode_reportService extends ReportBaseService<IReports_custom_material_barcode_reportServiceInParams, IReports_custom_material_barcode_reportServiceData> {

  protected reportReferenceName = 'custom_material_barcode_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_material_barcode_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_material_barcode_reportServiceInParams): Promise<IReports_custom_material_barcode_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_material_barcode_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_material_barcode_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        order_id:  $report.inParams.order_id 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_flow_material_barcode_report.get(dsParams);
      
      data.odata_barcode = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
