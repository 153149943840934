import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 


import { FootPrintManager_return_orders_gridComponent } from './FootPrintManager.return_orders_grid.component';
import { FootPrintManager_return_shipping_containers_gridComponent } from './FootPrintManager.return_shipping_containers_grid.component';
import { Locations_warehouses_dd_multiComponent } from './Locations.warehouses_dd_multi.component'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Returns_order_statuses_dd_multiComponent } from './Returns.order_statuses_dd_multi.component'
import { Returns_order_classes_dd_multiComponent } from './Returns.order_classes_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_return_orders_gridComponent),
    forwardRef(() => FootPrintManager_return_shipping_containers_gridComponent),
    forwardRef(() => Locations_warehouses_dd_multiComponent),
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Returns_order_statuses_dd_multiComponent),
    forwardRef(() => Returns_order_classes_dd_multiComponent),
  ],
  selector: 'FootPrintManager-returns_hub',
  templateUrl: './FootPrintManager.returns_hub.component.html'
})
export class FootPrintManager_returns_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_statuses: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order_classes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    input_scan: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      create: new ToolModel(new SplitButtonModel<{ create_rma_order: ButtonModel, create_blind_return: ButtonModel }>(
        'create',
        new ButtonStyles(null, null),
        false,
        'Create',
        'icon-ic_fluent_add_20_regular',
        [
          new ButtonModel('create_rma_order', new ButtonStyles(null, null), false, 'RMA order', ''),
          new ButtonModel('create_blind_return', new ButtonStyles(null, null), false, 'Blind return', '')
        ])
    )
  };

  actionbar = {
  };

 filters = {
    warehouses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouses', false)
,
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    order_statuses: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_statuses'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Statuses', false)
,
    order_classes: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order_classes'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order classes', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    input_scan: new FieldModel(new TextBoxModel(this.formGroup.controls['input_scan'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Scan container to begin blind receiving', false)
,
  };


  filtersets = {
  filters: new FieldsetModel('Filters', false, true, false),
  Scan: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      orders: new TabItemModel(
        this.rootTabGroup, 
        'Orders', 
        ),
      shipping_containers: new TabItemModel(
        this.rootTabGroup, 
        'Containers', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_orders_return_orders_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_orders_return_orders_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_orders_return_orders_grid_inParams_warehouseIds: number[];
    get $tabs_orders_return_orders_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_orders_return_orders_grid_inParams_statusIds: number[];
    get $tabs_orders_return_orders_grid_inParams_statusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.order_statuses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_statusIds, expr)) {
        this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_statusIds = expr;
      }
      return this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_statusIds;
    }
  
    cacheValueFor_$tabs_orders_return_orders_grid_inParams_orderClassIds: number[];
    get $tabs_orders_return_orders_grid_inParams_orderClassIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.order_classes.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_orderClassIds, expr)) {
        this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_orderClassIds = expr;
      }
      return this.cacheValueFor_$tabs_orders_return_orders_grid_inParams_orderClassIds;
    }
  
    get $tabs_orders_return_orders_grid_inParams_reference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.reference.control.value;
      
      return expr;
    }
  
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_ownerId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner.control.value;
      
      return expr;
    }
  
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds: number[];
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds: number[];
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.order_statuses.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds;
    }
  
    cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds: number[];
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.order_classes.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds, expr)) {
        this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds = expr;
      }
      return this.cacheValueFor_$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds;
    }
  
    get $tabs_shipping_containers_return_shipping_containers_grid_inParams_reference(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.reference.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_orders', { read: FootPrintManager_return_orders_gridComponent }) $tabs_orders: FootPrintManager_return_orders_gridComponent;
      @ViewChild('$tabs_shipping_containers', { read: FootPrintManager_return_shipping_containers_gridComponent }) $tabs_shipping_containers: FootPrintManager_return_shipping_containers_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_statusId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = 1;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  cacheValueFor_$fields_order_statuses_selector_inParams_statusIds: number[];
  get $fields_order_statuses_selector_inParams_statusIds(): number[] {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = [1,2,4,8];
    
    if(!isEqual(this.cacheValueFor_$fields_order_statuses_selector_inParams_statusIds, expr)) {
      this.cacheValueFor_$fields_order_statuses_selector_inParams_statusIds = expr;
    }
    return this.cacheValueFor_$fields_order_statuses_selector_inParams_statusIds;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Returns hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `View and manage returning orders and inventory`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.orders,
      this.tabs.shipping_containers,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Returns hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.order_statuses.control as SelectBoxModel).reset([1,2]);
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['owner']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.formGroup
      .controls['project']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['input_scan']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_input_scan_change();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_orders') {
      if (!isNil(this.$tabs_orders) && !this.tabs.orders.hidden) {
        this.$tabs_orders.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_shipping_containers') {
      if (!isNil(this.$tabs_shipping_containers) && !this.tabs.shipping_containers.hidden) {
        this.$tabs_shipping_containers.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_init');
  if ((await $operations.FootPrintManager.Enable_Locations_DefaultWarehouseByActivity.isAuthorized())) {
  
      let username = (await $flows.Utilities.get_username_flow({})).userName;
  
      let defaultWarehouseResult = (await $datasources.Locations.ds_get_default_warehouse_by_user.get({ userName: username })).result;
  
      $hub.filters.warehouses.control.value = $utils.isDefined(defaultWarehouseResult?.warehouseId) ? [defaultWarehouseResult.warehouseId] : null;
  }
  
  if ((await $operations.FootPrintManager.Disable_ReturnOrders_OrderCreation.isAuthorized())){
      $hub.toolbar.create.hidden = true;
  }
  }
  on_create_rma_order_clicked(event = null) {
    return this.on_create_rma_order_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_rma_order_clickedInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_create_rma_order_clicked');
  try {
      // Get outbound shipment(s)
      let orderIds = (await $shell.FootPrintManager.openoutbound_orders_eligible_for_return_gridDialog({ warehouseIds: $hub.filters.warehouses.control.value, ownerId: $hub.filters.owner.control.value, projectId: $hub.filters.project.control.value })).orderIds;
  
      if ($utils.isDefined(orderIds)) {
          let inboundOrderIds: number[] = [];
          let failedOrders: { orderId: number, reason: string }[] = [];
  
          for (let orderId of orderIds) {
              // Create RMA order(s)
              let result = (await $flows.Returns.create_return_order_from_outbound_flow({
                  outboundOrderId: orderId
              }));
  
              if ($utils.isDefined(result.reasons)) {
                  failedOrders.push({ orderId: orderId, reason: (await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue });
              }
              else {
                  inboundOrderIds.push(result.inboundOrderId);
              }
          }
  
          $hub.refresh();
  
          if ($utils.isDefined(failedOrders)) {
              await $shell.FootPrintManager.openErrorDialog(`Error creating RMA order`, null, inboundOrderIds.map(id => `Successfully created RMA for order ${id}`), 'Errors', failedOrders.map(o => ({ message: `Order ${o.orderId}`, detail: o.reason })));
          }
          else {
              await $shell.FootPrintManager.openInfoDialog(`Successfully created RMA order`, inboundOrderIds.map(id => `Successfully created RMA for order ${id}`).join(', '));
          }
  
          if (orderIds.length === 1 && inboundOrderIds.length === 1) {
              $shell.FootPrintManager.openreturn_order_editor({ orderId: inboundOrderIds[0]});
          }
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error creating RMA order', error.message);
  }
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_owner_change');
  $hub.filters.project.control.value = null;
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_project_change');
  if ($utils.isDefined($hub.filters.project.control.value) && !$utils.isDefined($hub.filters.owner.control.value)) {
      let project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: $hub.filters.project.control.value })).result[0];
  
      if ($utils.isDefined(project)) {
          $hub.filters.owner.control.value = project.OwnerId;
      }
  }
  }
  on_input_scan_change(event = null) {
    return this.on_input_scan_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_input_scan_changeInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_input_scan_change');
  try {
      if ($utils.isDefinedTrimmed($hub.filters.input_scan.control.value)) {
          let shippingContainer = (await $datasources.Returns.ds_get_shipping_container_by_shippingContainerCode.get({ shippingContainerCode: $hub.filters.input_scan.control.value.trim()})).result;
  
          if (!$utils.isDefined(shippingContainer)) { throw new Error(`Shipping container '${$hub.filters.input_scan.control.value.trim()}' does not exist or is not eligible for blind receiving.`); }
  
          $shell.FootPrintManager.openblind_return_shipping_container_hub({ shippingContainerId: shippingContainer.Id });
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.FootPrintManager.openErrorDialog('Error for scanned shipping container', error.message);
  }
  
  $hub.filters.input_scan.control.value = null;
  }
  on_create_blind_return_clicked(event = null) {
    return this.on_create_blind_return_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_blind_return_clickedInternal(
    $hub: FootPrintManager_returns_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
    this.logger.log('FootPrintManager', 'returns_hub.on_create_blind_return_clicked');
  
  // Initialize the first warehouse selected
  var warehouseId = null;
  
  if ($utils.isDefined($hub.filters.warehouses.control.value)) {
      warehouseId = $hub.filters.warehouses.control.value[0];
  };
  
  
  
  const dialogResult = (await $shell.FootPrintManager.openreturn_order_creation_form({
      ownerId: $hub.filters.owner.control.value,
      projectId: $hub.filters.project.control.value,
      warehouseId: $utils.isDefined(warehouseId) ? warehouseId : null
  }));
  
  
  
  }
  //#endregion private flows
}
